.register-page {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.register-page h1 {
  text-align: center;
}

.errmsg {
  color: red;
  margin-bottom: 10px;
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}

.register-form label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.register-form p {
  font-size: 0.8em; 
  margin-left: 10px; 
}

.register-form input[type="text"],
.register-form input[type="password"] {
  width: 100%; 
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.register-form button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #61dafb;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.input-requirements {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: left;
}

.line a {
  color: #61dafb;
  text-decoration: none;
}
